<template>
  <div class="wrapper">
    <!-- СТРАНИЦА С ТРЕБОВАНИЕМ ВВОДА ПАРОЛЯ -->
    <!-- <div v-if="!passwordAccepted" class="pass-check">
      <img style="margin-bottom: 30px" alt="Vue logo" src="../assets/skillfolio_logo.svg">
      <div style="margin-bottom: 30px; font-weight: bold; font-size: 18px;">
        НАВИГАТОР ПРОФЕССИЙ
      </div>
      <input
        v-model="password"
        class="pass-input"
        type="password"
        placeholder="Введите пароль..."
        @keyup.enter="checkPassword"
      >
      <div
        v-if="passError"
        style="color: red">Вы ввели неправильный пароль
      </div>
      <div
        class="pass-button"
        @click="checkPassword">Войти
      </div>
    </div> -->

    <div class="profession-navigator">
      <a class="profession-navigator__logo-wrapper" href="https://skillfolio.ru" title="Skillfolio">
        <img class="profession-navigator__logo" :src="`/images/logos/skillfolio_logo_lightblue.svg`" alt="">
      </a>
      <h1 class="profession-navigator__title"> НАВИГАТОР ПРОФЕССИЙ </h1>
      <div v-if="selected_interest == null || selected_skill == null" class="text-fill">
        {{ required_text }}
      </div>
      <section class="profession-navigator__tabs">
        <ul class="tabs__controls">
          <li
            v-for="(option, i) in Object.values(options)" :key="option + '_' + i"
            class="tabs__controls-btn"
            :class="{'item-active': i === selected_option_index}"
            @click.prevent="showTab(i)"
          >
            {{ option }}
          </li>
        </ul>
      </section>
      <div class="selection-container">
        <div
          v-for="(option, i) in Object.values(dataSet[Object.keys(options)[selected_option_index]])" :key="option + '_' + i"
          class="selection-container__item"
          :class="{'item-active-2': i === selected_item_highlight[selected_option]}"
          @click.prevent="selectItem(i)"
        >
          {{ option }}
        </div>
      </div>
      <div v-if="selected_interest !== null || selected_skill !== null" class="professions-container">
        <div v-if="show_professions === true" class="container-title">
          ВЫБЕРИТЕ ПРОФЕССИЮ
        </div>
        <div
          v-if="selected_profession.title !== undefined"
          class="show-container"
          @click.prevent="selected_profession = {}; show_professions = true"
        >
          РАЗВЕРНУТЬ СПИСОК ПРОФЕССИЙ
        </div>
        <div v-if="selected_profession.title !== undefined" class="container-title">
          {{ selected_profession.title }}
        </div>
        <div v-if="show_professions" class="professions-container__list">
          <div
            v-for="(profession, i) in professions"
            :key="profession + '_' + i"
            class="professions-container__list__item"
            @click.prevent="selectProfession(i)"
          >
            {{ profession.title }}
          </div>
        </div>

        <div v-if="selected_profession.title !== undefined">

          <div class="profession-description">
            <img class="profession-image" :src="selected_profession.img_persona_female">
            {{ selected_profession.desc }}
          </div>

          <div class="profession-requirements">
            <div v-show="selected_profession.universities.length" class="profession-requirements__title ">
              ВУЗЫ
            </div>
            <div v-show="selected_profession.universities.length" class="profession-requirements__title">
              ЭКЗАМЕНЫ ЕГЭ
            </div>
          </div>
          <div class="education" />
          <div v-for="vuz in selected_profession.universities" :key="vuz.name" class="profession-requirements__details">
            <div class="text-container">
              <div v-if="vuz.link">
                <a :href="vuz.link" target="_blank">{{ vuz.name }}</a>
              </div>
              <div v-else>
                {{ vuz.name }}
              </div>
            </div>
            <div class="text-container-ekz">
              <div v-for="ex in vuz.exams" :key="ex.name">
                {{ ex.name }}
              </div>
            </div>
          </div>

          <div v-show="selected_profession.colleges.length" class="profession-requirements">
            <div v-show="selected_profession.colleges.length" class="profession-requirements__title">
              ПРОФЕССИОНАЛЬНЫЕ УЧЕБНЫЕ ЗАВЕДЕНИЯ
            </div>
          </div>
          <div v-show="selected_profession.colleges.length" class="professional-education">
            <div v-for="suz in selected_profession.colleges" :key="suz.name" class="professional-education__item">
              <div v-if="suz.link">
                <a :href="suz.link" target="_blank">{{ suz.name }}</a>
              </div>
              <div v-else>
                {{ suz.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
/* global skillfolioApi */
// @ is an alias to /src

import CareerApi from '../services/CareerApi';

export default {
  name: 'navigator',

  components: {
  },

  data () {
    return {
      // passwordAccepted: null,
      // passError: false,
      // password: '',
      // actualPassword: 'navigator520',
      options: { interests: 'интересы', skills: 'навыки' },
      selected_option_index: 0,
      selected_item_highlight: { interests: null, skills: null },
      selected_interest: null,
      selected_skill: null,
      required_text: 'Чтобы отобразить подходящие профессии, выберите интерес, навык или оба поля',
      dataSet: {
        interests: {
          art: 'искусство',
          business: 'предпринимательство',
          digits: 'цифры',
          logic: 'логика',
          craft: 'крафт',
          music: 'музыка',
          nature: 'природа',
          people: 'люди',
          science: 'наука',
          sport: 'спорт',
          technology: 'технологии',
        },
        skills: {
          eq: 'эмоциональный интеллект',
          digital: 'цифровое мышление',
          communication: 'коммуникация',
          system: 'системное мышление',
          cooperation: 'кооперация',
          creative: 'креативное мышление',
          critical: 'критическое мышление',
        }
      },
      professions: null,
      selected_profession: {},
      show_professions: false,
    }
  },
  computed: {
    selected_option () {
      return Object.keys(this.options)[this.selected_option_index]
    }
  },
  methods: {
    checkPassword () {
      if (this.password === this.actualPassword) {
        this.passwordAccepted = true
        sessionStorage.setItem('password', this.password)
      }
      if (this.password !== this.actualPassword) {
        this.password = ''
        this.passError = true
      }
    },
    checksessionStoragePassword () {
      const password = sessionStorage.getItem('password')
      if (password === this.actualPassword) {
        this.passwordAccepted = true
      }
    },
    showTab (id) {
      return (id !== this.selected_option_index) ? this.selected_option_index = id : null
    },
    selectItem (index) {
      this.selected_item_highlight[this.selected_option] = index
      if (this.selected_option === 'interests') {
        this.selected_interest = Object.keys(this.dataSet[this.selected_option])[index]
      }
      if (this.selected_option === 'skills') this.selected_skill = Object.keys(this.dataSet[this.selected_option])[index]
      if (this.selected_interest !== null && this.selected_skill === null) this.required_text = 'Для вывода более точного списка профессий выберите навык'
      if (this.selected_interest === null && this.selected_skill !== null) this.required_text = 'Для вывода более точного списка профессий выберите интерес'
      if (this.selected_interest !== null || this.selected_skill !== null) {
        CareerApi.getProfessionsListNew(this.selected_skill, this.selected_interest).then(res => { this.professions = res.data })
        this.selected_profession = {}
        this.show_professions = true
      }
    },
    selectProfession (index) {
      this.selected_profession = { ...this.professions[index] }
      this.show_professions = false
    }
  }
}

</script>

<style lang="less" scoped>

.wrapper {
  display: flex;
  justify-content: center;
  min-width: 850px;
}

.pass-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.pass-input {
  font-size: 16px;
  width: 300px;
  line-height: 30px;
  margin-bottom: 20px;
  border-radius: 4px;
  border-width: 1px;
  outline: none;
}

.pass-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: white;
  font-weight: bold;
  background-color: black;
  margin: 15px 0px 15px 0px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  outline: none;
}

.profession-navigator {
  font-family: 'MuseoSans', 'Tahoma', sans-serif;
  line-height: 36px;
  font-weight: 500;
  width: 70%;
  min-height: 100vh;
  text-align: center;
  color: #2c3e50;
  max-width: 1200px;
  background: white;
  box-shadow: 1px 1px 4px #808080;
}

.profession-navigator__logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profession-navigator__logo {
  margin: 25px 0px 15px -15px;
  width: 30%;
}

.profession-navigator__title {
  margin-bottom: 30px;
}

.tabs__controls {  // ul
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  // margin-top: 70px;
  margin-bottom: 0px;
  text-align: left;

  & > li {
    flex: 1
  }
}

.tabs__controls-btn {  // li
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  margin: 0;
  // margin-bottom: @unitX2;
  padding: 24px 48px 24px 48px;
  text-transform: uppercase;
  transition:
    background-color 0.4s ease,
    box-shadow 0.4s ease;
  cursor: pointer;
  text-align: center;
  color: white;
  background-color: #3db8ff;
}

.item-active {
  background-color: #009dff;
  font-weight: 700;
  cursor: default;
}

.item-active-2 {
  // box-shadow: 1px 1px 3px;
  font-weight: 600;
  border: solid 1px black;
  border-radius: 3px;
  cursor: default;

  &:hover,
  &:focus,
  &:active {
    cursor: default;
  }
}

.tabs__content {  // ul
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.tabs__slide {  // li
  width: 100%;
  transition: opacity 0.4s ease;
}

.selection-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 50%;
  align-items: center;
  justify-content: center;
}

.selection-container__item {
  font-size: 14px;
  font-weight: 800;
  margin: 8px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;

  transition:
  background-color 0.4s ease,
  box-shadow 0.4s ease;
}

.text-fill {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
  font-family: 'MuseoSans', 'Tahoma', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.professions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  margin-top: 25px;
}

.show-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  height: 40px;
  padding: 0px 0px 0px 14px;
  cursor: pointer;
}

.show-container::after {
  background-image: url("/images/icons/down_arrow.svg");
  background-size: 14px 22px;
  display: inline-block;
  width: 14px;
  height: 22px;
  content:"";
  margin: 0px 0px 2.3px 12px;
}

.container-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  height: 60px;
}

.profession-image {
  height: 170px;
  width: 170px;
  margin: 0px 0px 15px 0px;
}

.profession-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  margin: 0px 55px 30px 55px;
}

.professions-container__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 30px;
  height: auto;
  width: 100%;
}

.professions-container__list__item {
  font-size: 20px;
  margin: 0px 6px 0px 6px;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 1px 1px 3px;
  }
}

.profession-requirements {
  display: flex;
  flex-direction: row;
  margin: 0px 30px 10px 30px;
}

.profession-requirements__title {
  text-align: left;
  font-weight: bold;
  padding-left: 25px;
  width: 50%;
}

.profession-requirements__details {
  display: flex;
  flex-direction: row;
  font-weight: 1000;
  min-height: 100px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  justify-content: space-between;
  border: solid 1px rgb(180, 180, 180);
  border-radius: 2px;
}

.text-container {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 50%;
  text-align: left;
  margin: 8px 25px 8px 25px;
}

.text-container-ekz {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  width: 50%;
  text-align: left;
  margin: 8px 25px 8px 25px;
}

.higher-education {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.professional-education {
  display: flex;
  flex-direction: column;
  width: auto;
  font-weight: 1000;
}

.professional-education__item {
  display: flex;
  text-align: start;
  align-items: center;
  font-size: 16px;
  padding: 0px 0px 0px 25px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  border: solid 1px rgb(180, 180, 180);
  border-radius: 2px;
}

.courses__list {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 3px;
}

@media screen and (max-width: 1024px) {
  .profession-navigator {
    height: auto;
    width: 100%;
    margin: 0px;
    box-shadow: none;
  }
  .wrapper {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 1px;
  background: rgb(250, 250, 250);
  }

  .profession-navigator__logo {
    margin: 20px 0px 10px -5px;
    width: 50%;
  }

  .profession-navigator__title {
    font-size: 24px;
    margin: 15px 0px 0px 0px;
  }

  .tabs__controls-btn {
    padding: 18px 36px 18px 36px;
    box-shadow: 1px 1px 2px #808080;
  }

  .text-fill {
    font-size: 16px;
    height: auto;
    margin-top: 10px;
    font-weight: 400;
  }

  .text-container {
    font-size: 16px;
    margin: 6px 12px 6px 12px;
    line-height: 26px;
  }

    .text-container-ekz {
    font-size: 16px;
    margin: 6px 12px 6px 12px;
    line-height: 26px;
  }

  .selection-container {
    justify-content: center;
    box-shadow: none;
    border-radius: 3px;
    margin: 4px 0px 0px 0px;
    padding: 4px;
  }

  .selection-container__item {
    font-size: 14px;
    margin: 0px 4px 0px 0px;
    padding: 0px 4px 0px 4px;
    line-height: 32px;
  }

  .professions-container {
    align-items: center;
    margin: 0px;
  }

  .professions-container__list {
    margin: 0px;
  }

  .professions-container__list__item {
    text-align: start;
    margin: 0px 12px 0px 12px;
    font-size: 16px;
  }

  .show-container {
    display: flex;
    font-size: 10px;
    padding: 0px;
    margin: 12px 0px 0px 12px;
  }

  .show-container::after {
    background-size: 10px 18px;
    width: 10px;
    height: 18px;
    margin: 0px 0px 2px 8px;
  }

  .container-title {
    font-size: 12px;
    height: 50px;
  }

  .profession-description {
    line-height: 20px;
    font-size: 16px;
    margin: 0px 12px 0px 12px;
  }

  .profession-image {
  height: 120px;
  width: 120px;
}

  .profession-requirements {
    margin: 0px;
  }

  .profession-requirements__title {
    font-size: 14px;
    padding-left: 0px;
    margin: 16px 0px 0px 12px;
    width: 100%;
  }

  .profession-requirements__details {
    margin: 0px 0px 10px 0px;
  }

  .professional-education__item {
    margin: 12px 0px 0px 0px;
    padding: 8px 12px 8px 12px;
    font-size: 16px;
    line-height: 24px;
  }

}

// FONTS

@font-face {
  font-family: "MuseoSans";
  src: url(/fonts/museo/MuseoSansLight.ttf);
  font-weight: 300;
}
@font-face {
  font-family: "MuseoSans";
  src: url(/fonts/museo/MuseoSansRegular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "MuseoSans";
  src: url(/fonts/museo/MuseoSansBold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "MuseoSans";
  src: url(/fonts/museo/MuseoSansBlack.ttf);
  font-weight: 900;
}

</style>
