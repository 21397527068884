import axios from 'axios';

const apiBaseUrl = window.location.href.indexOf('https') > -1 ? 'https://test-data.skillfolio.ru/career/' : 'http://91.90.239.124:49601/career/'
console.log('apiBaseUrl:', apiBaseUrl)

const CareerApi = {
  /**
   * Get professions list by object of skill, interest and list of projects
   *
   * @param {string} skill - Skill title
   * @param {string} interest - Interest title
   * @param {Array<string>} projects - Projects titles
   * @returns {Promise} Promise
   */
  getProfessionsListNew: function (skill, interest, projects = null) {
    let data = {
      skill: skill,
      interest: interest,
      projects: projects
    }
    return axios.post(apiBaseUrl + 'get_professions', data)
  }
}


export default CareerApi

